import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'folder',
    loadChildren: () => import('./pages/folder/folder.module').then(m => m.FolderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./pages/myaccount/myaccount.module').then(m => m.MyaccountPageModule)
  },
  {
    path: 'personal-detail',
    loadChildren: () => import('./pages/personal-detail/personal-detail.module').then(m => m.PersonalDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-point',
    loadChildren: () => import('./pages/my-point/my-point.module').then(m => m.MyPointPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'benefits',
    loadChildren: () => import('./pages/benefits/benefits.module').then(m => m.BenefitsPageModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'edit-information',
    loadChildren: () => import('./pages/edit-information/edit-information.module').then(m => m.EditInformationPageModule)
  },
  {
    path: 'request-account-info',
    loadChildren: () => import('./pages/request-account-info/request-account-info.module').then( m => m.RequestAccountInfoPageModule)
  },
  {
    path: 'unsubscribe-wa-newsletter',
    loadChildren: () => import('./pages/unsubscribe-wa-newsletter/unsubscribe-wa-newsletter.module').then( m => m.UnsubscribeWaNewsletterPageModule)
  },
  {
    path: 'notif',
    loadChildren: () => import('./pages/notif/notif.module').then(m => m.NotifPageModule)
  }, {
    path: 'desain',
    loadChildren: () => import('./pages/desain/desain.module').then(m => m.DesainPageModule)
  }, {
    path: 'notfound',
    loadChildren: () => import('./pages/notfound/notfound.module').then(m => m.NotfoundPageModule)
  },
  { path: '**', redirectTo: '/notfound' },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
