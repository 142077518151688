import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'client-keys': environment.API_KEY
});

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public API_URL_CK = environment.API_URL;
  public API_URL_DELIVERY = environment.API_URL_DELIVERY;

  constructor(
    private http: HttpClient
  ) { }

  /**
  * Promo Double Point
  * @param data 
  */

  // Get Provice
  public getProvince(): Observable<any> {
    return this.http.get(this.API_URL_CK + 'location/prov', { headers });
  }
  // get City
  public getCity(prov): Observable<any> {
    return this.http.get(this.API_URL_CK + 'location/city?province=' + prov, { headers });
  }
  // get Kabupaten
  public getDistrict(prov, city): Observable<any> {
    return this.http.get(this.API_URL_CK + `location/district?province=${prov}&city=${city}`, { headers });
  }

  // Customer Login
  public getCustomersLogin(username: any, pass: any, company:any): Observable<any> {
    return this.http.post(this.API_URL_CK + `auth/login`, { username, pass, company }, { headers });
  }

  // Customer Information
  public getCustomerinformation(noClient: any): Observable<any> {
    return this.http.get(this.API_URL_CK + `member/informationclient?noClient=${noClient}`, { headers });
  }

  // Point History
  public getCustomerPointHistory(noClient: any, page: any): Observable<any> {
    return this.http.get(this.API_URL_CK + `member/pointhistory?noClient=${noClient}&page=${page}`, { headers });
  }

  // update personal detail
  public savePersonaldetail(jsonData): Observable<any> {
    return this.http.post(this.API_URL_CK + 'member/update', jsonData, { headers });
  }
  // chnage password 
  public saveChangePassword(jsonData): Observable<any> {
    return this.http.post(this.API_URL_CK + 'auth/changepass', jsonData, { headers });
  }

  public updatevipmemberunsubscribe(jsonData: any):Observable<any> {
    return this.http.post(`${environment.API_URL_VPS}vip/updatevipmemberunsubscribe`, jsonData, { headers: headers });
  }

}
